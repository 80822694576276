import './App.css';

function App() {
  return (
    <>
    <div className="App">
     Wish to aquire this domain? Send an email to {`letstalk@${window.location.hostname}`}
    </div>
    <div className='App' style={{marginTop: '20px'}}>
      Please type the subject in this format: DOMAIN ACQUISITION: {window.location.hostname}
    </div>
    <div className='App' style={{marginTop: '20px'}}>
      Include any details you wish to share about your interest in this domain. We do not respond to inquiries that do not include the subject line above.
    </div>
    <div className='App' style={{marginTop: '20px'}}>
      Do not attach any file (documents, images, etc) or include any link in your email or it will be sent to spam.
    </div>
    </>
  );
}

export default App;
